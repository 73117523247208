/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../styles/layout.scss"
import Footer from "./Footer"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.privacytools.com.br/public_api/banner/style/Pk1A912171.css?t=1"
      ></link>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Helmet>
        <script src="/smoothscrol.js" />
        <script>
          {`
          document.querySelectorAll('.js-smooth').forEach((anchor) => {
            anchor.addEventListener('click', (event) => {
              vanillaSmoothie.scrollTo(event.target.getAttribute('href'), {
                duration: 500
              })
            })
          })`}
        </script>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-56455811-3"
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-56455811-3');gtag('config', 'AW-806441179');`}
        </script>
        {/* <!-- Event snippet for Casas Revo conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->  */}
        <script>
          {`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-806441179/FHVZCI7FjMoBENuhxYAD', 'event_callback': callback }); return false; }`}
        </script>
        {/* <!-- Facebook Pixel Code --> */}
        <script>
          {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1121801264670823'); 
fbq('track', 'PageView');
`}
        </script>
        <noscript>
          {` <img height="1" width="1" src="https://www.facebook.com/tr?id=1121801264670823&ev=PageView&noscript=1"/>`}
        </noscript>
        {/* <!-- End Facebook Pixel Code --> */}

        {/* <!-- Hotjar Tracking Code for http://revobrasil.com.br --> */}
        <script>
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:929198,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
        <script
          type="text/javascript"
          src="https://cdn.privacytools.com.br/public_api/banner/script/bottom/Pk1A912171.js?t=1"
        ></script>
      </Helmet>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
