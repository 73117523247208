import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <nav className="navbar navbar-expand-lg navbar-dark shadow-sm fixed-top">
    <div className="container">
      <a className="navbar-brand js-smooth mx-auto" href="#inicio">
        <img
          height="40"
          src="/revo-negocios-imobiliarios.svg"
          alt="Revo Brasil"
        />
      </a>
      <div className="collapse navbar-collapse" id="main-navbar">
        <ul className="navbar-nav ml-md-auto">
          <li className="nav-item active">
            <a
              href="#simulacao"
              title="Faça a simulação agora"
              className="js-smooth btn btn-success text-uppercase py-2 px-3"
            >
              Faça a simulação agora
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
